$responsive-menu-breakpoint: 750px;

.pagedEditor {
  display: flex;
  padding: 15px;
}

.pagedEditorActionBar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 5px 5px 15px;
  background: $color-brand-complement-lightest;
  border-bottom: solid 1px $color-border;

  .button.secondary {
    background: rgb(131, 149, 173);
  }
}

.pagedEditorActionBar > .pagedEditorActionBarStatus {
  display: flex;
  flex-grow: 2;
  align-items: center;
  padding-left: 1em;
  color: #555;
  font-size: 0.8rem;
  font-weight: bold;
}

.pagedEditorActionBar > .pagedEditorActionBarButtons {
  justify-content: flex-end;
}

.pagedEditorValidations {
  background: red;
  padding: 12px;
  border-radius: 4px;
  color: white;
  position: relative;
  margin: 12px 12px 0px;

  ul {
    padding-left: 15px;
    margin: 3px;
  }
}

.pagedEditorValidationsSubSection {
  padding: 5px;
}

.pagedEditorValidationsSubSectionHeading {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px;
}

.pagedEditorValidationsDismisser {
  position: absolute;
  top: 10px;
  right: 10px;
}

.pagedEditorMainMenu {
  display: flex;
  flex-direction: column;

  flex-shrink: 0;

  &.pagedEditorMainMenuDesktop {
    display: block;
    @media screen and (max-width: $responsive-menu-breakpoint) {
      display: none;
    }
  }

  &.pagedEditorMainMenuMobile {
    display: none;
    padding: 20px;
    overflow: auto;

    @media screen and (max-width: $responsive-menu-breakpoint) {
      display: block;
      position: fixed;
      background: white;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
    }
  }
}

.pagedEditorMobileNav {
  display: none;
  background-color: #2185d0;
  color: white;
  padding: 10px 20px;
  width: 100%;
  border: none;
  cursor: pointer;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: #1678c2;
  }

  @media screen and (max-width: $responsive-menu-breakpoint) {
    display: flex;
  }
}

.pagedEditorMobileNavLabel {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
}

.pagedEditorMainMenuButtonOuterWrapper {
  border-radius: 5px;
  background: #8395ad;
  height: 65px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;

  &.active {
    background: #001434;
  }

  &.isWhite {
    background: white;

    .pagedEditorMainMenuButton {
      color: black;
    }
  }
}

.pagedEditorMainMenuButtonOuterWrapperWithSubButton {
  height: 95px;
}

.pagedEditorMainMenuButton {
  width: 90px;
  height: 65px;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
  text-align: center;

  &.active {
    .pagedEditorMainMenuSubButton {
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }

  @media screen and (max-width: $responsive-menu-breakpoint) {
    width: 100%;
  }
}

.pagedEditorMainMenuButtonValidationIndicator {
  background: red;
  color: white;
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 8px;
  border-radius: 100px;
  transform: translate(50%, -50%);
}

.pagedEditorMainMenuDragHandle {
  color: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 0px;
  left: 8px;
}

.pagedEditorMainMenuSubButtons {
  display: flex;
  width: 100%;
  border-top: solid 1px rgba(255, 255, 255, 0.2);
}

// The button within the button (e.g. page deletion, move page up, move page down)
.pagedEditorMainMenuSubButton {
  background: rgba(0, 0, 0, 0.2);
  padding: 5px 1px;
  color: white;
  width: 33.33%;
  transition: background 0.15s ease-in-out;
  border: none;
  border-right: solid 1px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  text-align: center;

  &:last-child {
    border-right: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &:disabled {
    cursor: not-allowed;
    transition: none;
    background: rgba(255, 255, 255, 0.3) !important;
  }
}

.pagedEditorContent {
  flex-grow: 1;
  padding: 0 10px 20px;
}
