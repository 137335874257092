$editor-tools-mobile-breakpoint: 600px;

.rich-editor {
  display: flex;
  @media screen and (max-width: $editor-tools-mobile-breakpoint) {
    flex-direction: column;
  }
}

.rich-editor__toolbar-wrapper {
  display: flex;
  flex-direction: column;
  height: fit-content;
  z-index: 100;

  @media screen and (max-width: $editor-tools-mobile-breakpoint) {
    top: 60px;
    flex-direction: row;
  }

  &.rich-editor__toolbar-wrapper--scrolled {
    position: fixed;
    top: 65px;
    @media screen and (max-width: $editor-tools-mobile-breakpoint) {
      top: 55px;
    }
  }
}

.rich-editor__toolbar {
  width: 118px;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  margin-right: 10px;
  padding: 5px 8px 0;
  background-color: #fff;
  box-shadow: 0px 1px 2px 1px #e0e0e0;
  border: 1px solid #cdcaca;
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;

  @media screen and (max-height: 740px) {
    height: 80vh;
    width: 125px;
  }

  @media screen and (max-width: $editor-tools-mobile-breakpoint) {
    width: 100% !important;
    justify-content: flex-start;
    padding: 5px;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 0.99);

    .rich-editor__tool-menu {
      width: 200px !important;
      margin-top: 2px;
    }

    .ui.medium.icon.button {
      margin: 2px;
    }
  }
}

.rich-editor__toolbar--title {
  width: 100%;
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 5px;

  &::first-letter {
    text-transform: capitalize;
  }

  @media screen and (max-width: $editor-tools-mobile-breakpoint) {
    text-align: left;
  }
}

.rich-editor__toolbar .ui.button {
  margin: 0 0 8px 0;
  width: 46px;
  height: 46px;

  i {
    background-image: none;
  }
}

.rich-editor__toolbar select {
  margin: 0 0 8px 0;
  width: 100px;
  font-size: 90%;
}

.rich-editor__content {
  flex-grow: 2;
  border: 1px solid rgba(34, 36, 38, 0.2);
  background-color: #fff;
  padding: 0.75em 1em;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10rem;

  &.rich-editor__content--scrolled {
    margin-left: 128px;

    @media screen and (max-height: 650px) {
      margin-left: 135px;
    }

    @media screen and (max-width: $editor-tools-mobile-breakpoint) {
      margin-left: 0px;
    }
  }
}

.rich-editor__block-tool-label {
  font-size: 0.75rem;
  padding: 5px;
  position: absolute;
  right: 100%;
  text-align: right;
  top: 50%;
  transform: translateY(-40%);
  padding-right: 5px;
  line-height: 1;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.26s;
}
.rich-editor__block-tools:hover {
  .rich-editor__block-tool-label {
    opacity: 1;
  }
}

.rich-editor__element {
  background-color: #e8e8e8;
  transition: background-color 0.26s;
}

.rich-editor__element--selected {
  box-shadow: 0 0 0 3px #b4d5ff;
}

.post-description--rich-post,
.learning-module-editor {
  padding: 0.8em;
  .rich-editor__content {
    flex-grow: 2;
    background-color: transparent;
    border: 1px solid rgba(25, 31, 36, 0.2);
    border: 0;
    padding: 0;
    border-radius: 5px;
  }

  .rich-editor__content > [data-slate-editor='true'] {
    outline: none;
  }

  [data-slate-editor='true'] > [data-slate-node='element'],
  [data-medshr-slate-container='true'] > [data-slate-node='element'] {
    min-height: 30px;
    padding: 5px 10px;
    border-radius: 1px;

    margin-bottom: 1em;
  }

  [data-slate-editor='true'] > [data-slate-node='element']:last-child,
  [data-medshr-slate-container='true']
    > [data-slate-node='element']:last-child {
    margin-bottom: 0;
  }

  [data-slate-editor='true'] > [data-slate-node='element'] {
    background-color: #e8e8e8;
    padding: 35px 10px 10px 10px;

    @media screen and (max-width: $editor-tools-mobile-breakpoint) {
      padding: 35px 10px 10px 10px;
    }
  }

  [data-medshr-slate-container='true'] > [data-slate-node='element'] {
    background-color: #e8e8e8;
    padding: 35px 0 10px 0;

    @media screen and (max-width: $editor-tools-mobile-breakpoint) {
      padding: 35px 0 10px 0;
    }
  }

  [data-slate-editor='true'] > .cms-columns[data-slate-node='element'] {
    padding: 35px 0 10px;
    background-color: transparent;
  }

  [data-medshr-slate-container='true']
    > .cms-column[data-slate-node='element'] {
    padding: 0;
    margin-bottom: 0;
    width: 50%;
  }

  [data-medshr-slate-container='true']
    > .cms-column--0[data-slate-node='element'] {
    padding-right: 1em;
  }

  [data-medshr-slate-container='true']
    > .cms-column--1[data-slate-node='element'] {
    padding-left: 1em;
  }

  [data-slate-editor='true'] > [data-slate-void='true'] {
    background-color: transparent;
    padding: 0;
  }

  .rich-editor__content {
    ul,
    ol {
      li {
        margin-left: 1.5em;
      }

      p {
        margin: 0;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 0;
    }

    // Note: these styles should match medshr-frontend/components/Learning/blocks/Text.tsx
    h1 {
      font-weight: 900;
      font-size: 1.7rem;
    }
    h2 {
      font-weight: 700;
      font-size: 1.5rem;
    }
    h3 {
      font-weight: 700;
      font-size: 1.3rem;
    }
    p {
      font-size: 16px;
    }

    .mcq-block__wrapper input {
      font-size: 16px;
    }

    .document-block__label {
      max-width: 400px;
      color: #333;
      margin-bottom: 10px;
    }

    blockquote {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
      font-size: 16px;
      padding: 10px;
    }
  }
}

.rich-editor__element:not(.rich-editor__element--selected):hover {
  background-color: #e0e0e0;
}

[data-slate-void][data-selected] {
  sup {
    font-weight: bold;
    background-color: #9cb7f8;
  }
}

.open-graph-block__image img {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
}

.open-graph-block__about {
  background-color: #eee;
  padding: 10px;
}

.open-graph-block--small {
  display: flex;
}
.open-graph-block--small img {
  width: 300px;
}

.cms-columns {
  display: flex;
}

.cms-column {
  width: 50%;
}

.learning-module-editor {
  [data-slate-editor='true'] > .cms-columns[data-slate-node='element'] {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }

  [data-medshr-slate-container='true']
    > .cms-column[data-slate-node='element'] {
    background-color: transparent;
    border-left: 1px solid #ccc;
    border-radius: 0;
    padding-left: 1em;
    padding-right: 0;
  }
  [data-medshr-slate-container='true']
    > .cms-column--0[data-slate-node='element'] {
    border-left: 0;
    padding-left: 0;
    padding-right: 1em;
  }
}

.learning-module-field--cover-logo .cms-upload-field__preview {
  padding: 10px;
  height: 100px;
  background-color: rgba(15, 33, 63, 0.85);
  img {
    height: 80px;
  }
}

.learning-block__gallery-wrapper {
  .image-gallery-thumbnails-wrapper {
    position: absolute;
    left: 0;
    right: 0;
  }
}

.learning-block__gallery--desktop {
  .image-gallery-thumbnails-wrapper {
    overflow-x: auto;
  }
  .image-gallery-thumbnails {
    overflow: visible;
  }
}

.learning-block__gallery--square {
  .image-gallery-thumbnail {
    width: 80px;
  }

  .image-gallery-content {
    .image-gallery-slide .image-gallery-image {
      max-height: none;
    }
  }

  .image-gallery-slide .image-gallery-image {
    object-fit: cover;
    aspect-ratio: 1/1;
    width: 100%;
  }
}

.flex-grid {
  display: flex;
  flex-direction: column;
}

.flex-grid__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.flex-grid__col {
  padding-right: 1em;
}
.flex-grid__col:last-child {
  padding-right: 0;
}
